var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pt-4 mb-8 mx-2",
      attrs: { "max-width": _vm.containerWidth }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "text-center text-subtitle-2 text-sm-h6 text-wrap inqliNavy--text mb-2"
        },
        [_vm._v(" " + _vm._s(_vm.chartTitle) + " ")]
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap justify-center" },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }