<template>
  <div class="border ma-2 rounded-lg pa-2">
    <vue-word-cloud
      :style="style"
      :words="wordCloudData"
      :color="color"
      :enterAnimation="enterAnimation"
      :animationOverlap="0"
      :rotation="rotation"
      :fontSizeRatio="4"
      font-family="poppins"
      :spacing="0.3"
    />
  </div>
</template>

<script>
import VueWordCloud from "vuewordcloud";

export default {
  components: {
    VueWordCloud: VueWordCloud,
  },
  props: {
    wordCloudData: [],
    chartSize: {
      type: Number,
    },
  },
  data() {
    return {
      purple: this.$vuetify.theme.themes.light.inqliPurple,
      navy: this.$vuetify.theme.themes.light.inqliNavy,
      enterAnimation: {
        opacity: 0,
        transform: "rotateZ(90deg)",
      },
      rotation: () => {
        const rand = Math.random();
        return rand > 0.8
          ? 0
          : rand > 0.6
          ? 1 / 4
          : rand > 0.4
          ? 3 / 4
          : rand > 0.2
          ? 1 / 8
          : 7 / 8;
      },
    };
  },
  computed: {
    style() {
      return `width: ${this.chartSize - 16}px; height: ${
        this.chartSize / 1.2 - 16
      }px;`;
    },
    color() {
      const myGreen = this.$vuetify.theme.themes.light.inqliGreen;
      const myPurple = this.$vuetify.theme.themes.light.inqliPurple;
      const myNavy = this.$vuetify.theme.themes.light.inqliNavy;
      const myRed = this.$vuetify.theme.themes.light.inqliRed;
      const myYellow = this.$vuetify.theme.themes.light.inqliYellow;
      return ([, weight]) =>
        weight === 1
          ? myRed
          : weight > 0.75
          ? myYellow
          : weight > 0.5
          ? myGreen
          : weight > 0.25
          ? myPurple
          : myNavy;
    },
  },
};
</script>
