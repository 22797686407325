<template>
  <GChart
    :settings="{ packages: ['corechart'] }"
    type="PieChart"
    :data="chartData"
    class="border ma-2 rounded-lg"
    :options="chartOptions"
  />
</template>

<script>
import { GChart } from "vue-google-charts";
export default {
  components: {
    GChart,
  },
  props: {
    chartSize: {
      type: Number,
    },
    pieChartData: [],
  },
  computed: {
    chartData() {
      if (!this.pieChartData) return [];
      return [["Content Type", "Member's Submission"], ...this.pieChartData];
    },
    chartOptions() {
      return {
        height: this.chartSize / 1.2,
        width: this.chartSize,
        backgroundColor: { fill: "transparent" },
        is3D: true,
        colors: [
          this.$vuetify.theme.themes.light.inqliGreen,
          this.$vuetify.theme.themes.light.inqliPurple,
          this.$vuetify.theme.themes.light.inqliRed,
        ],
      };
    },
  },
};
</script>
