<template>
  <layout>
    <inqli-header />
    <div class="chart-group-container">
      <chart-container
        :windowWidth="windowWidth"
        :chartSize="chartSize"
        chartTitle="Active Members"
      >
        <pie-chart :chartSize="chartSize" :pieChartData="pieChartData" />
        <line-chart
          :chartData="cumulativeMemberCountData"
          :chartOptions="cumulativeMemberCountOptions"
          :chartSize="chartSize"
        />
        <geo-chart :chartSize="chartSize" :membersData="members.data" />
      </chart-container>
      <chart-container
        :windowWidth="windowWidth"
        :chartSize="chartSize"
        chartTitle="Member's Interest"
      >
        <word-cloud :wordCloudData="wordCloudData" :chartSize="chartSize" />
      </chart-container>
    </div>
  </layout>
</template>
<script>
import ChartContainer from "../app/components/charts/ChartContainer.vue";
import LineChart from "../app/components/charts/LineChart.vue";
import WordCloud from "../app/components/charts/WordCloud.vue";
import GeoChart from "../app/components/charts/GeoChart.vue";
import PieChart from "../app/components/charts/PieChart.vue";
import Layout from "../app/components/layouts/DefaultLayout.vue";
import Header from "../app/components/headers/DefaultHeader";
import sendRequest from "../app/utils/send-request-helper";

const MONTH = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default {
  data() {
    return {
      loading: true,
      org: {},
      members: {
        data: [],
        relationship: [],
      },
      title: "Organization Profile",
      wordCloudData: [],
      pieChartData: [],
      windowWidth: window.innerWidth,
    };
  },
  components: {
    ChartContainer,
    LineChart,
    WordCloud,
    GeoChart,
    PieChart,
    layout: Layout,
    "inqli-header": Header,
  },
  async mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    try {
      const orgId = this.$route.params.orgId;
      // check user permission (assume user is logged in)
      // if this request is successful, user has permission
      this.checkUserPermission(orgId);
      // get organization info
      this.org = await this.getOrgInfo(orgId);
      this.members.data = await this.getOrgMembers(orgId);
      this.members.relationship = await this.getMemberRelationshipInfo(
        this.members.data,
        orgId
      );
      this.wordCloudData = await this.getMemberInterest(orgId);
      this.pieChartData = await this.getOrgContentCount(orgId);
    } catch (error) {
      this.$store.dispatch(
        "callAlert",
        "An error occured while sending request."
      );
    }
  },
  methods: {
    async checkUserPermission(orgId) {
      const userId = this.$store.state.user.username;
      await sendRequest({
        url: `/relationships/member?source_id=${userId}&dest_id=${orgId}`,
        isAuth: true,
        method: "GET",
      });
    },
    async getOrgInfo(orgId) {
      const orgResponse = await sendRequest({
        url: `/resources/organizations/${orgId}`,
        method: "GET",
      });
      return orgResponse;
    },
    async getOrgMembers(orgId) {
      const memberResponse = await sendRequest({
        url: `/relationships/member?dest_id=${orgId}`,
        isAuth: true,
        method: "GET",
      });
      return memberResponse;
    },
    async getMemberInterest(orgId) {
      const response = await sendRequest({
        url: `/resources/organizations/word_cloud/${orgId}`,
        method: "GET",
        isAuth: true,
      });
      const interestData = [];
      for (const word in response) {
        interestData.push([word, response[word]]);
      }
      return interestData;
    },
    async getOrgContentCount(orgId) {
      const response = await sendRequest({
        url: `/resources/organizations/content_count/${orgId}`,
        method: "GET",
        isAuth: true,
      });
      const contentData = [];
      for (const content in response) {
        contentData.push([content, response[content]]);
      }
      return contentData;
    },
    async getMemberRelationshipInfo(memberList, orgId) {
      const memberRelationship = [];
      memberList.forEach(async (member) => {
        const response = await sendRequest({
          url: `/relationships/member?dest_id=${orgId}&source_id=${member.id}`,
          method: "GET",
          isAuth: true,
        });
        memberRelationship.push(response);
      });
      return memberRelationship;
    },
  },
  computed: {
    chartSize() {
      return Math.min(this.windowWidth - 50, 400);
    },
    cumulativeMemberCountData() {
      const totalMembers = this.members.relationship.length;
      const newMemberPerMonth = Array(6).fill(0);
      const todayDate = new Date();
      const thisYear = todayDate.getFullYear();
      const thisMonth = todayDate.getMonth();

      function getRowDataForMonth(newMemberPerMonth, monthIndex) {
        return [
          // get the month abbreviation
          MONTH[(thisMonth + 12 - monthIndex) % 12],
          // get cumulative members up to this month
          totalMembers -
            newMemberPerMonth.slice(0, monthIndex).reduce((a, b) => a + b, 0),
          // get new members for the month
          newMemberPerMonth[monthIndex],
        ];
      }

      this.members.relationship.map((member) => {
        const memberJoinedDate = new Date(member.created_on);
        const monthOffset = (memberJoinedDate.getFullYear() - thisYear) * 12;
        const joinedMonthFromToday =
          thisMonth - (memberJoinedDate.getMonth() + monthOffset);
        if (joinedMonthFromToday < 6) {
          newMemberPerMonth[joinedMonthFromToday]++;
        }
      });

      return [
        ["Month", "Total", "New Members"],
        ...[5, 4, 3, 2, 1, 0].map((el) =>
          getRowDataForMonth(newMemberPerMonth, el)
        ),
      ];
    },

    cumulativeMemberCountOptions() {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      const startingMonth = (currentMonth + 7) % 12;
      const startingYear =
        currentMonth > startingMonth ? "" : `, ${currentYear - 1}`;
      return {
        title: "Cumulative members over time",
        subtitle: `From ${MONTH[startingMonth]}${startingYear} to ${MONTH[currentMonth]}, ${currentYear}`,
        options: {
          vAxis: {
            gridlines: {
              multiple: 1,
            },
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-group-container {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  width: 90%;
  align-items: flex-start;
  margin: auto;
}
@media (max-width: $breakpoint-tablet) {
  .chart-group-container {
    flex-wrap: wrap;
    width: 100%;
  }
}
</style>
