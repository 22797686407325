var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "border ma-2 rounded-lg pa-2" },
    [
      _c("vue-word-cloud", {
        style: _vm.style,
        attrs: {
          words: _vm.wordCloudData,
          color: _vm.color,
          enterAnimation: _vm.enterAnimation,
          animationOverlap: 0,
          rotation: _vm.rotation,
          fontSizeRatio: 4,
          "font-family": "poppins",
          spacing: 0.3
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }