<template>
  <GChart
    :settings="{
      packages: ['geochart'],
      mapsApiKey: 'AIzaSyDzVZ9dQmJyR9_i4HPvXc5L2_OxfZosc9o',
    }"
    type="GeoChart"
    :data="chartData"
    :options="chartOptions"
    class="border ma-2 rounded-lg"
  />
</template>

<script>
import { GChart } from "vue-google-charts";
import alphaToUn from "./utils/alphaToUn";
import { unM49 } from "un-m49";

export default {
  props: {
    membersData: [],
    chartSize: {
      type: Number,
    },
  },
  components: {
    GChart,
  },
  data() {
    return {
      region: "world",
      title: "",
    };
  },
  computed: {
    chartOptions() {
      return {
        region: this.region,
        displayMode: "markers",
        width: this.chartSize,
        height: this.chartSize / 1.2,
        title: "some words",
        datalessRegionColor: this.$vuetify.theme.themes.light.inqliBackground,
        colorAxis: {
          colors: [
            this.$vuetify.theme.themes.light.inqliPurple,
            this.$vuetify.theme.themes.light.inqliGreen,
          ],
        },
        backgroundColor: {
          fill: "transparent",
        },
      };
    },
    chartData() {
      const members = this.membersData;
      const markerList = [["City", "Members"]];
      const countriesList = new Set();

      // Create an array of cities and amount of members
      for (const member of members) {
        countriesList.add(member.location.country);
        const currentLocation = `${member.location.city}, ${member.location.state}, ${member.location.country}`;
        const locationIndex = markerList.findIndex(
          (city) => city[0] === currentLocation
        );
        if (locationIndex === -1) {
          markerList.push([currentLocation, 1]);
        } else {
          markerList[locationIndex][1]++;
        }
      }
      this.lowestCommonAncestor(countriesList);
      return markerList;
    },
  },
  methods: {
    lowestCommonAncestor(countriesList) {
      // path finding
      function findPath(node, path, visited) {
        if (node === "001" || !node) return;
        let parentNode = unM49.find((el) => el.code === node).parent;
        if (visited.has(parentNode)) return;

        visited.add(parentNode);
        let indexInPath = path.findIndex((el) => el === parentNode);
        if (indexInPath > -1) {
          for (node of path.splice(0, indexInPath)) {
            visited.add(node);
          }
          return path;
        }
        return findPath(parentNode, path, visited);
      }

      // set path for first node
      function findFirstPath(node, path) {
        if (node === "001" || !node) return path;
        const parentNode = unM49.find((el) => el.code === node).parent;
        path.push(parentNode);
        return findFirstPath(parentNode, path);
      }

      // Set the map region to see all represented countries
      const iter = countriesList.values();
      if (countriesList.size === 1) {
        this.region = iter.next().value;
        this.title = this.region;
      } else {
        let path = findFirstPath(alphaToUn[iter.next().value], []);
        const visited = new Set();
        let nextCountry = {};
        while (path.length > 1) {
          nextCountry = iter.next().value;
          if (!nextCountry) break;
          findPath(alphaToUn[nextCountry], path, visited);
        }

        this.region = path[0] === "001" ? "world" : path[0];
      }
    },
  },
};
</script>
