<template>
  <v-card :max-width="containerWidth" class="pt-4 mb-8 mx-2">
    <div
      class="text-center text-subtitle-2 text-sm-h6 text-wrap inqliNavy--text mb-2"
    >
      {{ chartTitle }}
    </div>
    <div class="d-flex flex-wrap justify-center">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      containerWidth: 0,
    };
  },
  props: {
    chartTitle: {
      type: String,
      default: "",
    },
    chartSize: {
      type: Number,
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.setContainerWidth();
  },
  methods: {
    setContainerWidth() {
      if (this.windowWidth > 1040) {
        this.containerWidth = 2 * (this.chartSize + 20);
        return;
      }
      this.containerWidth = this.chartSize + 20;
    },
  },
  watch: {
    windowWidth() {
      this.setContainerWidth();
    },
  },
};
</script>
