var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("GChart", {
    staticClass: "border ma-2 rounded-lg",
    attrs: {
      settings: { packages: ["corechart"] },
      data: _vm.chartData,
      options: _vm.options,
      createChart: function(el, google) {
        return new google.visualization.LineChart(el)
      }
    },
    on: { ready: _vm.onChartReady }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }