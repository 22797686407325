<template>
  <GChart
    :settings="{ packages: ['corechart'] }"
    :data="chartData"
    :options="options"
    :createChart="(el, google) => new google.visualization.LineChart(el)"
    @ready="onChartReady"
    class="border ma-2 rounded-lg"
  />
</template>

<script>
import { GChart } from "vue-google-charts";
export default {
  props: {
    chartData: {
      type: Array,
    },
    chartOptions: {
      type: Object,
    },
    chartSize: {
      type: Number,
    },
  },
  data() {
    return {
      chartsLib: null,
    };
  },
  components: {
    GChart,
  },
  computed: {
    options() {
      if (!this.chartsLib) return null;
      return {
        title: this.chartOptions.subtitle,
        height: this.chartSize / 1.2,
        width: this.chartSize,
        legend: { position: "bottom" },
        colors: [
          this.$vuetify.theme.themes.light.inqliGreen,
          this.$vuetify.theme.themes.light.inqliPurple,
        ],
        backgroundColor: {
          fill: "transparent",
        },
        chartArea: {
          left: 20,
          right: 12,
          top: 36,
          width: "100%",
          height: "65%",
        },
        ...this.chartOptions.options,
      };
    },
  },
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
  },
};
</script>
